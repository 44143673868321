import {
  Box,
  Button,
  Group,
  Image,
  ImageProps,
  LoadingOverlay,
  Modal,
  ModalProps as MantineModalProps,
  Stack,
  Text,
  Tooltip,
  UnstyledButton,
  useMantineTheme,
} from '@mantine/core';
import { find, getOr } from 'lodash/fp';
import React, { useCallback, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import {
  DeviceType,
  GettingStartedToursNamesEnum,
  SpaceType,
  useAddDevice,
  useCompleteGettingStartedTour,
  useDeviceModels,
  useIsFirstClaimedDevice,
  useOrganizationConfig,
  usePortalCapabilities,
  useSpace,
} from '@portals/api/organizations';
import { ModalCenteredMediaLayout } from '@portals/core';
import {
  isCustomerFeatureAll,
  isCustomerFeatureLabOnly,
  ModalProps,
} from '@portals/framework';
import { useOpenRouteModal } from '@portals/framework/route-modals';
import { ReactComponent as TrendUp } from '@portals/icons/bulk/trend-up.svg';
import { ReactComponent as QuestionCircle } from '@portals/icons/linear/question-circle.svg';
import { useOpenModal } from '@portals/redux';
import { toastrSuccess } from '@portals/redux/actions/toastr';
import { NO_DEVICE_MONITORING_LICENSES } from '@portals/utils';

import { AddDeviceForm } from './add-device-form/AddDeviceForm';
import { ChangeSpaceField } from './add-device-form/fields/ChangeSpaceField';
import { OnSubmitFormParams } from './add-device-modal.types';
import NoLicense from './NoLicense';
import { DeviceTroubleshootingSteps } from '../device-troubleshooting-steps/DeviceTroubleshootingStepsModal';
import { TourCompletedModalProps } from '../TourCompletedModal';

export interface AddDeviceModalProps
  extends ModalProps<{ spaceId: SpaceType['id'] | undefined }> {}

export function AddDeviceModal({ data, closeMe }: AddDeviceModalProps) {
  const theme = useMantineTheme();
  const deviceModels = useDeviceModels();
  const addDevice = useAddDevice();
  const isNoLicense = useSelector(
    getOr(false, ['data', 'system_issues', NO_DEVICE_MONITORING_LICENSES])
  );
  const navigate = useNavigate();
  const openModal = useOpenModal();
  const completeGettingStartedTour = useCompleteGettingStartedTour();
  const portalCapabilities = usePortalCapabilities();
  const isFirstClaimedDevice = useIsFirstClaimedDevice();
  const organizationConfig = useOrganizationConfig();

  const { spaceId } = data;

  const [currentSpaceId, onSetCurrentSpaceId] = useState(spaceId);

  const openRouteModal = useOpenRouteModal();

  const [selectedDevice, setSelectedDevice] = useState<DeviceType | null>(null);
  const [showClaimDeviceSuccessfulModal, setShowClaimDeviceSuccessfulModal] =
    useState(false);
  const [showTroubleshootingStepsModal, setShowTroubleshootingStepsModal] =
    useState<string | null>(null);

  const space = useSpace({
    spaceId: currentSpaceId,
    fallbackToRootSpace: true,
  });

  const showClaimDeviceTourCompletedModal = useCallback(() => {
    if (!portalCapabilities || !organizationConfig) {
      return false;
    }

    return (
      isCustomerFeatureAll(portalCapabilities.onboarding_guides) ||
      isCustomerFeatureLabOnly(
        portalCapabilities.onboarding_guides,
        organizationConfig.lab
      )
    );
  }, [portalCapabilities, organizationConfig]);

  const onSubmit = (params: OnSubmitFormParams) => {
    const { name, model, mac, sn, cloud_id, zoom_room, c2c_id } = params;

    addDevice.mutate(
      {
        space_id: currentSpaceId,
        name: name?.trim(),
        device_model_id: model,
        mac,
        sn,
        cloud_id,
        zoom_room,
        c2c_id,
      },
      {
        onSuccess,
        onError: () => setShowTroubleshootingStepsModal(model),
      }
    );
  };

  const onSuccess = async (device: DeviceType) => {
    toastrSuccess(
      'Successfully added device',
      `${device.name || device.partner.model}`
    );

    const isFirstClaimed = await isFirstClaimedDevice.refetch();

    if (isFirstClaimed.data) {
      completeGettingStartedTour.mutate('claim_device_completed');

      if (showClaimDeviceTourCompletedModal()) {
        openModal<TourCompletedModalProps['data']>('TourCompletedModal', {
          title: 'You’ve just claimed your first device',
          nextTourTitle: 'Monitor and manage devices',
          nextTourDescription:
            'Learn how to effectively monitor, manage, and configure your devices, empowering you to keep track of their performance and make necessary adjustments as needed.',
          nextTourIcon: <TrendUp />,
          onNextTourClick: () => {
            navigate(
              `/overview/?active_tour=${GettingStartedToursNamesEnum.Monitoring}`
            );
          },
        });

        closeMe();
        return;
      }
    }

    setSelectedDevice(device);
    setShowClaimDeviceSuccessfulModal(true);
  };

  if (!deviceModels.isFetched) {
    return (
      <Modal
        opened
        onClose={closeMe}
        centered
        withCloseButton={false}
        title="Add device to space"
        radius="lg"
        styles={modalStyles}
      >
        <Stack h={370}>
          <LoadingOverlay visible />
        </Stack>
      </Modal>
    );
  }

  if (isNoLicense) {
    return (
      <Modal
        opened
        onClose={closeMe}
        title="Add device to space"
        radius="lg"
        styles={modalStyles}
      >
        {addDevice.isLoading ? <LoadingOverlay visible /> : null}

        <NoLicense closeMe={closeMe} />
      </Modal>
    );
  }

  if (showClaimDeviceSuccessfulModal && selectedDevice) {
    return (
      <ModalCenteredMediaLayout
        radius="lg"
        onClose={closeMe}
        opened
        title="Device claimed successfully"
        media={
          <Image
            withPlaceholder
            src={selectedDevice?.model_settings?.media?.img}
            width={96}
            styles={imageStyles}
          />
        }
        footer={
          <Group position="center" grow>
            <Button
              variant="default"
              onClick={() => setShowClaimDeviceSuccessfulModal(false)}
            >
              Claim another device
            </Button>

            <Button
              data-testid="show-claimed-device-button"
              onClick={() => {
                openRouteModal({
                  modalId: 'device',
                  pathParams: [selectedDevice.id],
                });

                closeMe();
              }}
            >
              Show device
            </Button>
          </Group>
        }
      >
        <Stack align="center" spacing="xl">
          <Text align="center">
            Your device has been successfully claimed. You can now enjoy all
            features and benefits of our platform with this device
          </Text>

          {selectedDevice.has_token_licenses ? (
            <Group spacing={2}>
              <UnstyledButton
                onClick={() => {
                  openRouteModal({
                    modalId: 'device',
                    pathParams: [
                      selectedDevice.id,
                      'licenses?redeem-license-code=true',
                    ],
                  });

                  closeMe();
                }}
              >
                <Text
                  underline
                  color="blue_gray.9"
                  fw={500}
                  data-testid="active-license-with-code-link"
                >
                  Activate a license with a code for this device
                </Text>
              </UnstyledButton>

              <Tooltip
                w={300}
                multiline
                label="This device has additional features which can be unlocked with a device license. If you have a code for such a license, follow this link to redeem it."
              >
                <QuestionCircle
                  width={18}
                  height={18}
                  color={theme.colors.gray[4]}
                />
              </Tooltip>
            </Group>
          ) : null}
        </Stack>
      </ModalCenteredMediaLayout>
    );
  }

  const deviceModel =
    showTroubleshootingStepsModal &&
    find({ id: showTroubleshootingStepsModal }, deviceModels.data);

  return (
    <>
      {showTroubleshootingStepsModal && deviceModel ? (
        <DeviceTroubleshootingSteps
          name="DeviceTroubleshootingSteps"
          data={{
            deviceModel,
          }}
          closeMe={() => setShowTroubleshootingStepsModal(null)}
        />
      ) : null}

      <Modal
        opened
        onClose={closeMe}
        title="Add device to space"
        radius="lg"
        styles={modalStyles}
        sx={{ display: showTroubleshootingStepsModal ? 'none' : 'unset' }}
      >
        {addDevice.isLoading ? <LoadingOverlay visible /> : null}

        <Stack spacing="xxl">
          <Box px="xxl" pt="xxl">
            <ChangeSpaceField
              space={space}
              onSetSpaceId={onSetCurrentSpaceId}
            />
          </Box>

          <AddDeviceForm handleClose={closeMe} onSubmit={onSubmit} />
        </Stack>
      </Modal>
    </>
  );
}

const modalStyles: MantineModalProps['styles'] = (theme) => ({
  header: {
    padding: theme.spacing.xxl,
    borderBottom: `1px solid ${theme.colors.gray[2]}`,
  },

  body: {
    padding: 0,
  },
});

const imageStyles: ImageProps['styles'] = () => ({
  placeholder: {
    width: 96,
    height: 96,
  },
});
