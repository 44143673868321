import { lazy } from 'react';

import {
  ChangePassword,
  IntegrationsDemoPage,
  IntegrationsRouteModal,
  isCustomerFeatureAll,
  isCustomerFeatureLabOnly,
  Page404,
  Page500,
  ResetPassword,
  SETTING_PAGE_ID_URL_PARAM_NAME,
  SignOut,
  Welcome,
} from '@portals/framework';
import { ReactComponent as ArchiveBook } from '@portals/icons/linear/archive-book.svg';
import { ReactComponent as BoxIcon } from '@portals/icons/linear/box.svg';
import { ReactComponent as Briefcase } from '@portals/icons/linear/briefcase.svg';
import { ReactComponent as CloudConnection } from '@portals/icons/linear/cloud-connection.svg';
import { ReactComponent as Data } from '@portals/icons/linear/data.svg';
import { ReactComponent as FolderCloud } from '@portals/icons/linear/folder-cloud.svg';
import { ReactComponent as Hierarchy3 } from '@portals/icons/linear/hierarchy-3.svg';
import { ReactComponent as MonitorMobbile } from '@portals/icons/linear/monitor-mobbile.svg';
import { ReactComponent as Setting2 } from '@portals/icons/linear/setting-2.svg';
import { ReactComponent as ShopIcon } from '@portals/icons/linear/shop.svg';
import { ReactComponent as ShoppingCart } from '@portals/icons/linear/shopping-cart.svg';
import { ReactComponent as Task } from '@portals/icons/linear/task.svg';
import { ReactComponent as Ticket } from '@portals/icons/linear/ticket.svg';
import { ReactComponent as Warning2 } from '@portals/icons/linear/warning-2.svg';
import {
  CategoryRouteItem,
  OrganizationConfigurationType,
  RoutesMap,
  RoutesType,
  TenantType,
} from '@portals/types';
import { isGettingStartedToursDone } from '@portals/utils';

import { ClaimDeviceByQrCode } from './components/ClaimDeviceByQrCode';
import { TicketsCounter, TicketsIndicator } from './components/TicketsCounter';
import { Signin } from './pages/auth/Signin';
import { Signup } from './pages/auth/Signup';
import { AutoSignIn } from './pages/auto-sign-in/AutoSignIn';
import { ExternalStore } from './pages/external-store/ExternalStore';
import {
  IncidentsCounter,
  IncidentsIndicator,
} from './pages/incidents/incidents-counters';
import { Overview } from './pages/overview/Overview';
import { Invoices } from './pages/products/pages/invoices/Invoices';
import { Orders } from './pages/products/pages/orders/Orders';
import { PurchasedProducts } from './pages/products/pages/purchased-products/PurchasedProducts';
import { Subscriptions } from './pages/products/pages/subscriptions/Subscriptions';
import { Setup } from './pages/setup/Setup';
import { ConnectRouteModalSelector } from './route-modals/Connect/ConnectRouteModalSelector';
import { DeviceWrapper } from './route-modals/Device/DeviceWrapper';
import { Order } from './route-modals/Order/Order';
import { ServiceProviders } from './route-modals/Service-providers/ServiceProviders';
import { PurchasedProductRoutePanel } from './route-panels/product/PurchasedProductRoutePanel';
import { SubscriptionRoutePanel } from './route-panels/subscription/SubscriptionRoutePanel';
import { TicketRoutePanel } from './route-panels/ticket/TicketRoutePanel';
import {
  CLOUD_ID_SEARCH_PARAM,
  PARTNER_SEARCH_PARAM,
} from '../constants/global-search-params.constants';

const auth: RoutesType<OrganizationConfigurationType> = [
  {
    path: ['/auth', '/auth/sign-in/:partner_name?'],
    id: 'Sign In',
    component: Signin,
  },
  {
    path: '/auth/sign-out',
    id: 'Sign In',
    component: SignOut,
  },
  {
    id: 'Sign Up',
    path: ['/auth/sign-up/:partner_name?'],
    component: Signup,
  },
  {
    path: '/auth/auto-sign-in',
    id: 'Auto Sign In',
    component: AutoSignIn,
  },
  {
    path: '/auth/change-password/:partner_name?',
    id: 'Change Password',
    component: ChangePassword,
  },
  {
    path: '/auth/welcome/:partner_name?',
    id: 'Welcome',
    component: Welcome,
  },

  {
    path: '/auth/reset-password/:partner_name?',
    id: 'Reset Password',
    component: ResetPassword,
  },
  {
    path: '/auth/404',
    id: '404 Page',
    component: Page404,
  },
  {
    path: '/auth/500',
    id: '500 Page',
    component: Page500,
  },
];

const dashboard: CategoryRouteItem<OrganizationConfigurationType>[] = [
  {
    id: 'claim',
    headerId: '',
    canAccessRoute: ({ configuration }) =>
      !isCustomerFeatureAll(configuration?.portal_capabilities?.b2c_view) &&
      !isCustomerFeatureLabOnly(
        configuration?.portal_capabilities?.b2c_view,
        configuration?.[TenantType.Organization].lab
      ),
    childRoutes: [
      {
        path: ['/claim', '/c/:cloudId'],
        component: ClaimDeviceByQrCode,
        isVisibleInSidebar: () => false,
      },
    ],
  },
  {
    id: 'oauth2',
    headerId: '',
    childRoutes: [
      {
        path: '/oauth2/:id',
        id: 'sidebar.oauth2',
        component: lazy(() => import('./components/OAuth2Flow')),
        isVisibleInSidebar: () => false,
      },
    ],
  },

  {
    id: 'checkout',
    headerId: '',
    canAccessRoute: ({ isAdmin }) => isAdmin,
    childRoutes: [
      {
        path: ['/checkout/card-confirmation'],
        component: lazy(
          () => import('./pages/checkout/steps/card-confirmation')
        ),
        isVisibleInSidebar: () => false,
      },
      {
        path: ['/checkout', '/checkout/:id'],
        icon: ShoppingCart,
        component: lazy(() => import('./pages/checkout')),
        isVisibleInSidebar: () => false,
      },
    ],
  },
  {
    id: 'setup',
    headerId: '',
    canAccessRoute: ({ configuration }) =>
      isCustomerFeatureAll(
        configuration?.portal_capabilities?.onboarding_guides
      ) ||
      isCustomerFeatureLabOnly(
        configuration?.portal_capabilities?.onboarding_guides,
        configuration?.[TenantType.Organization].lab
      ),
    childRoutes: [
      {
        path: ({ configuration }) => {
          const showSetupPageAsDefaultPage =
            !isGettingStartedToursDone(configuration) &&
            !configuration?.onboarding_tours?.dismiss &&
            (isCustomerFeatureAll(
              configuration?.portal_capabilities?.onboarding_guides
            ) ||
              isCustomerFeatureLabOnly(
                configuration?.portal_capabilities?.onboarding_guides,
                configuration?.[TenantType.Organization].lab
              ));

          return showSetupPageAsDefaultPage ? ['/', '/setup'] : ['/setup'];
        },
        id: 'sidebar.setupOrganization',
        icon: Task,
        component: Setup,
        isVisibleInSidebar: ({ configuration }) =>
          !configuration?.onboarding_tours?.dismiss &&
          !isGettingStartedToursDone(configuration),
      },
    ],
  },
  {
    id: 'controlCenter',
    headerId: 'sidebar.controlCenter',
    canAccessRoute: ({ configuration }) =>
      configuration?.portal_capabilities?.device_monitoring === true &&
      !isCustomerFeatureAll(configuration?.portal_capabilities?.b2c_view) &&
      !isCustomerFeatureLabOnly(
        configuration?.portal_capabilities?.b2c_view,
        configuration?.[TenantType.Organization].lab
      ),
    childRoutes: [
      {
        path: ({ configuration }) => {
          const showOverviewPageAsDefaultPage =
            isGettingStartedToursDone(configuration) ||
            configuration?.onboarding_tours?.dismiss ||
            (!isCustomerFeatureAll(
              configuration?.portal_capabilities?.onboarding_guides
            ) &&
              !isCustomerFeatureLabOnly(
                configuration?.portal_capabilities?.onboarding_guides,
                configuration?.[TenantType.Organization].lab
              ));

          return showOverviewPageAsDefaultPage
            ? [
                '/',
                '/overview',
                '/overview/:spaceId',
                '/overview/:spaceId/config',
                '/overview/:spaceId/config/widgets',
                '/overview/:spaceId/settings/:settingsTab',
              ]
            : [
                '/overview',
                '/overview/:spaceId',
                '/overview/:spaceId/config',
                '/overview/:spaceId/config/widgets',
                '/overview/:spaceId/settings/:settingsTab',
              ];
        },
        id: 'sidebar.organizationOverview',
        icon: Data,
        component: Overview,
      },
      {
        path: '/incidents/*',
        id: 'sidebar.incidents',
        icon: Warning2,
        component: lazy(() => import('./pages/incidents')),
        badgeComponent: IncidentsCounter,
        indicatorComponent: IncidentsIndicator,
      },
      {
        path: ['/incident/:id'],
        id: 'sidebar.incidents',
        icon: Warning2,
        component: lazy(() => import('./pages/incidents/IncidentDetails')),
        isVisibleInSidebar: () => false,
      },
    ],
  },
  {
    id: 'assetManagement',
    headerId: 'sidebar.assetManagement',
    canAccessRoute: ({ configuration }) =>
      configuration?.portal_capabilities?.device_monitoring === true &&
      !isCustomerFeatureAll(configuration?.portal_capabilities?.b2c_view) &&
      !isCustomerFeatureLabOnly(
        configuration?.portal_capabilities?.b2c_view,
        configuration?.[TenantType.Organization].lab
      ),
    childRoutes: [
      {
        path: ['/devices'],
        id: 'sidebar.devices',
        icon: MonitorMobbile,
        component: lazy(() => import('./pages/devices')),
      },
      {
        path: ['/files', '/files/:id'],
        id: 'sidebar.files',
        icon: FolderCloud,
        component: lazy(() => import('./pages/files')),
      },
      {
        path: ['/warranties'],
        id: 'sidebar.warranties',
        icon: ArchiveBook,
        canAccessRoute: ({ configuration }) =>
          configuration?.portal_capabilities?.self_managed_warranties === true,
        component: lazy(() =>
          import('./pages/warranties').then(({ Warranties }) => ({
            default: Warranties,
          }))
        ),
      },
    ],
  },
  {
    id: 'digitalProducts',
    headerId: 'sidebar.digitalProducts',
    canAccessRoute: ({ configuration }) =>
      !isCustomerFeatureAll(configuration?.portal_capabilities?.b2c_view) &&
      !isCustomerFeatureLabOnly(
        configuration?.portal_capabilities?.b2c_view,
        configuration?.[TenantType.Organization].lab
      ),
    childRoutes: [
      {
        path: ({ configuration }) => {
          const showStorePageAsDefaultPage =
            (configuration?.portal_capabilities.device_monitoring === true &&
              (!isGettingStartedToursDone(configuration) ||
                !configuration?.onboarding_tours?.dismiss)) ||
            (!isCustomerFeatureAll(
              configuration?.portal_capabilities?.b2c_view
            ) &&
              !isCustomerFeatureLabOnly(
                configuration?.portal_capabilities?.b2c_view,
                configuration?.[TenantType.Organization].lab
              ));

          return showStorePageAsDefaultPage
            ? ['/store', '/store/:storeListingSlug']
            : ['/', '/store', '/store/:storeListingSlug'];
        },
        id: 'sidebar.store',
        icon: ShopIcon,
        component: lazy(() =>
          import('./pages/store/Store').then(({ Store }) => ({
            default: Store,
          }))
        ),
        canAccessRoute: ({ configuration }) =>
          configuration?.portal_capabilities?.store === true,
      },
      {
        path: ['/products', '/products/*'],
        id: 'sidebar.digitalProducts.products',
        icon: BoxIcon,
        component: lazy(() =>
          import('./pages/products/Products').then(({ Products }) => ({
            default: Products,
          }))
        ),
        canAccessRoute: ({ configuration }) =>
          configuration?.portal_capabilities?.purchased_products === true,
        children: [
          {
            path: ['/products/purchased-products/*'],
            id: 'purchasedProducts',
            component: PurchasedProducts,
          },
          {
            path: '/products/subscriptions/*',
            id: 'subscriptions',
            component: Subscriptions,
          },
          {
            path: '/products/orders/*',
            id: 'orders',
            component: Orders,
          },
          {
            path: '/products/invoices/*',
            id: 'invoices',
            component: Invoices,
          },
        ],
      },
    ],
  },
  {
    id: 'support',
    headerId: 'sidebar.support',
    canAccessRoute: ({ configuration }) =>
      configuration?.portal_capabilities?.device_monitoring === true &&
      configuration?.portal_capabilities?.support_center === true &&
      !isCustomerFeatureAll(configuration?.portal_capabilities?.b2c_view) &&
      !isCustomerFeatureLabOnly(
        configuration?.portal_capabilities?.b2c_view,
        configuration?.[TenantType.Organization].lab
      ),
    childRoutes: [
      {
        path: ['/tickets', '/tickets/active', '/tickets/all', '/tickets/:id'],
        id: 'sidebar.tickets',
        icon: Ticket,
        component: lazy(() => import('./pages/tickets')),
        badgeComponent: TicketsCounter,
        indicatorComponent: TicketsIndicator,
      },
    ],
  },
  {
    id: 'msps',
    headerId: '',
    canAccessRoute: ({ configuration }) =>
      Boolean(configuration?.feature_flags?.managed_service_providers) &&
      !isCustomerFeatureAll(configuration?.portal_capabilities?.b2c_view) &&
      !isCustomerFeatureLabOnly(
        configuration?.portal_capabilities?.b2c_view,
        configuration?.[TenantType.Organization].lab
      ),
    childRoutes: [
      {
        path: '/msp/overview',
        id: 'sidebar.msp',
        icon: CloudConnection,
        component: lazy(() => import('./pages/experts')),
        isVisibleInSidebar: ({ configuration }) =>
          Boolean(configuration?.feature_flags?.managed_service_providers),
      },
    ],
  },
  {
    id: 'service_provides',
    headerId: '',
    canAccessRoute: ({ isAdmin, configuration }) =>
      isAdmin &&
      (isCustomerFeatureAll(
        configuration?.portal_capabilities?.service_providers
      ) ||
        isCustomerFeatureLabOnly(
          configuration?.portal_capabilities?.service_providers,
          configuration?.[TenantType.Organization].lab
        )) &&
      !isCustomerFeatureAll(configuration?.portal_capabilities?.b2c_view) &&
      !isCustomerFeatureLabOnly(
        configuration?.portal_capabilities?.b2c_view,
        configuration?.[TenantType.Organization].lab
      ),
    childRoutes: [
      {
        path: ['/m/certified_service_provider'],
        id: 'sidebar.certified_service_provider',
        icon: Briefcase,
        component: ServiceProviders,
        isVisibleInSidebar: ({ configuration }) =>
          (isCustomerFeatureAll(
            configuration?.portal_capabilities?.service_providers
          ) ||
            isCustomerFeatureLabOnly(
              configuration?.portal_capabilities?.service_providers,
              configuration?.[TenantType.Organization].lab
            )) &&
          !isCustomerFeatureAll(configuration?.portal_capabilities?.b2c_view) &&
          !isCustomerFeatureLabOnly(
            configuration?.portal_capabilities?.b2c_view,
            configuration?.[TenantType.Organization].lab
          ),
      },
    ],
  },
  {
    id: 'settings',
    headerId: '',
    canAccessRoute: ({ isAdmin, configuration }) =>
      isAdmin &&
      !isCustomerFeatureAll(configuration?.portal_capabilities?.b2c_view) &&
      !isCustomerFeatureLabOnly(
        configuration?.portal_capabilities?.b2c_view,
        configuration?.[TenantType.Organization].lab
      ),
    childRoutes: [
      {
        path: ['/settings', `/settings/:${SETTING_PAGE_ID_URL_PARAM_NAME}/*`],
        hasBorder: true,
        id: 'sidebar.settings',
        icon: Setting2,
        component: lazy(() =>
          import('./pages/settings/Settings').then(({ Settings }) => ({
            default: Settings,
          }))
        ),
      },
    ],
  },
  {
    id: 'new_card_confirmation',
    headerId: '',
    canAccessRoute: ({ isAdmin }) => isAdmin,
    childRoutes: [
      {
        path: ['/new-card-confirmation'],
        component: lazy(
          () => import('./pages/settings/credit-cards/card-confirmation')
        ),
        isVisibleInSidebar: () => false,
      },
    ],
  },
  {
    id: 'integrations',
    headerId: '',
    canAccessRoute: ({ configuration }) =>
      configuration?.feature_flags?.integrations_demo === true &&
      !isCustomerFeatureAll(configuration?.portal_capabilities?.b2c_view) &&
      !isCustomerFeatureLabOnly(
        configuration?.portal_capabilities?.b2c_view,
        configuration?.[TenantType.Organization].lab
      ),
    childRoutes: [
      {
        path: ['/integrations', '/integrations/:integration'],
        id: 'sidebar.integrations',
        icon: Hierarchy3,
        component: IntegrationsDemoPage,
      },
    ],
  },

  // B2C only
  {
    id: 'b2c',
    headerId: '',
    canAccessRoute: ({ configuration }) =>
      isCustomerFeatureAll(configuration?.portal_capabilities?.b2c_view) ||
      isCustomerFeatureLabOnly(
        configuration?.portal_capabilities?.b2c_view,
        configuration?.[TenantType.Organization].lab
      ),
    childRoutes: [
      {
        path: ({ configuration }) =>
          configuration?.portal_capabilities?.store === true
            ? ['/', '/store', '/store/:storeListingSlug']
            : ['/store', '/store/:storeListingSlug'],
        id: 'sidebar.store',
        icon: ShopIcon,
        component: lazy(() =>
          import('./pages/store/Store').then(({ Store }) => ({
            default: Store,
          }))
        ),
        canAccessRoute: ({ configuration }) =>
          configuration?.portal_capabilities?.store === true,
      },
      {
        path: ({ configuration }) =>
          configuration?.portal_capabilities?.store !== true
            ? ['/', '/products', '/products/*']
            : ['/products', '/products/*'],
        id: 'sidebar.digitalProducts.products',
        icon: BoxIcon,
        component: lazy(() =>
          import('./pages/products/Products').then(({ Products }) => ({
            default: Products,
          }))
        ),
        canAccessRoute: ({ configuration }) =>
          configuration?.portal_capabilities?.purchased_products === true,
        children: [
          {
            path: ['/products/purchased-products/*'],
            id: 'purchasedProducts',
            component: PurchasedProducts,
          },
          {
            path: '/products/subscriptions/*',
            id: 'subscriptions',
            component: Subscriptions,
          },
          {
            path: '/products/orders/*',
            id: 'orders',
            component: Orders,
          },
          {
            path: ['/products/invoices', '/products/invoices/*'],
            id: 'invoices',
            component: Invoices,
          },
        ],
      },
      {
        path: ['/settings', `/settings/:${SETTING_PAGE_ID_URL_PARAM_NAME}/*`],
        hasBorder: true,
        id: 'sidebar.settings',
        icon: Setting2,
        component: lazy(() =>
          import('./pages/settings/Settings').then(({ Settings }) => ({
            default: Settings,
          }))
        ),
        canAccessRoute: ({ isAdmin }) => isAdmin,
      },
    ],
  },
];

const routeModals: RoutesMap<OrganizationConfigurationType>['routeModals'] = [
  {
    id: 'device',
    path: '/m/device/:device_id/:tab_id?',
    component: DeviceWrapper,
    backgroundPath: '/devices',
    canAccessRoute: ({ configuration }) =>
      !isCustomerFeatureAll(configuration?.portal_capabilities?.b2c_view) &&
      !isCustomerFeatureLabOnly(
        configuration?.portal_capabilities?.b2c_view,
        configuration?.[TenantType.Organization].lab
      ),
    generatePath: ([deviceId, tabId]: [string, string | undefined]) => {
      const route = `/m/device/${deviceId}`;

      return tabId ? `${route}/${tabId}` : route;
    },
  },
  {
    id: 'order',
    path: '/m/order/:order_id',
    component: Order,
    backgroundPath: '/products/orders',
    generatePath: ([orderId, queryParam]: [string, string | undefined]) =>
      queryParam ? `/m/order/${orderId}?${queryParam}` : `/m/order/${orderId}`,
  },
  {
    id: 'connect',
    path: '/m/connect',
    component: ConnectRouteModalSelector,
    backgroundPath: '/overview',
    generatePath: ([partnerName, cloudId]) => {
      const searchParams = new URLSearchParams();

      if (partnerName) {
        searchParams.set(PARTNER_SEARCH_PARAM, partnerName);
      }
      if (cloudId) {
        searchParams.set(CLOUD_ID_SEARCH_PARAM, cloudId);
      }

      if (searchParams.size) {
        return `/m/connect?${searchParams.toString()}`;
      }

      return '/m/connect';
    },
  },
  {
    id: 'certified_service_provider',
    path: '/m/certified_service_provider',
    component: ServiceProviders,
    backgroundPath: '/overview',
    canAccessRoute: ({ configuration }) =>
      !isCustomerFeatureAll(configuration?.portal_capabilities?.b2c_view) &&
      !isCustomerFeatureLabOnly(
        configuration?.portal_capabilities?.b2c_view,
        configuration?.[TenantType.Organization].lab
      ) &&
      (isCustomerFeatureAll(
        configuration?.portal_capabilities?.service_providers
      ) ||
        isCustomerFeatureLabOnly(
          configuration?.portal_capabilities?.service_providers,
          configuration?.[TenantType.Organization].lab
        )),
    generatePath: () => '/m/certified_service_provider',
  },
  {
    id: 'integrations',
    path: ['/m/integrations', '/m/integrations/:integration'],
    component: IntegrationsRouteModal,
    backgroundPath: '/integrations',
    canAccessRoute: ({ configuration }) =>
      Boolean(configuration?.feature_flags?.integrations_demo) &&
      !isCustomerFeatureAll(configuration?.portal_capabilities?.b2c_view) &&
      !isCustomerFeatureLabOnly(
        configuration?.portal_capabilities?.b2c_view,
        configuration?.[TenantType.Organization].lab
      ),
    generatePath: () => `/m/integrations`,
  },
];

const routePanels: RoutesMap<OrganizationConfigurationType>['routePanels'] = [
  {
    id: 'ticket',
    path: '/p/ticket/:ticket_id',
    component: TicketRoutePanel,
    backgroundPath: '/tickets',
    canAccessRoute: ({ configuration }) => {
      return (
        configuration?.feature_flags?.hide_new_tickets_ui !== true &&
        configuration?.portal_capabilities?.device_monitoring === true &&
        configuration?.portal_capabilities?.support_center === true &&
        !isCustomerFeatureAll(configuration?.portal_capabilities?.b2c_view) &&
        !isCustomerFeatureLabOnly(
          configuration?.portal_capabilities?.b2c_view,
          configuration?.[TenantType.Organization].lab
        )
      );
    },
    generatePath: ([ticketId]: string[]) => `/p/ticket/${ticketId}`,
  },
  {
    id: 'incident',
    path: '/p/incident/:incident_id',
    component: lazy(() => import('./route-panels/incident/IncidentRoutePanel')),
    backgroundPath: '/incidents',
    canAccessRoute: ({ configuration }) =>
      configuration?.portal_capabilities?.device_monitoring === true &&
      !configuration?.portal_capabilities?.b2c_view,
    generatePath: ([incidentId]: string[]) => `/p/incident/${incidentId}`,
  },
  {
    id: 'subscription',
    path: '/p/subscription/:subscription_id',
    component: SubscriptionRoutePanel,
    backgroundPath: '/products/subscriptions',
    canAccessRoute: ({ configuration }) =>
      configuration?.portal_capabilities?.purchased_products === true,
    generatePath: ([subscriptionId]: string[]) =>
      `/p/subscription/${subscriptionId}`,
  },
  {
    id: 'purchased_product',
    path: '/p/purchased_product/:purchased_product_id',
    component: PurchasedProductRoutePanel,
    backgroundPath: '/products/purchased-products',
    canAccessRoute: ({ configuration }) =>
      configuration?.portal_capabilities?.purchased_products === true,
    generatePath: ([purchasedProductId]: string[]) =>
      `/p/purchased_product/${purchasedProductId}`,
  },
];

const externalStore: RoutesType<OrganizationConfigurationType> = [
  {
    path: ['/store', '/store/:storeListingSlug'],
    id: 'externalStore',
    component: ExternalStore,
  },
];

export const desktopRoutes: RoutesMap<OrganizationConfigurationType> = {
  dashboard,
  routeModals,
  routePanels,
  externalStore,
  auth,
};
