import { Box, createStyles } from '@mantine/core';
import { motion } from 'framer-motion';
import React, { useMemo } from 'react';

import {
  Navbar,
  OrganizationGlobalSubscription,
  Page404,
  QuickNavProvider,
  useHasSupportSeat,
  usePermissionAccess,
} from '@portals/framework';
import { useAppConfig } from '@portals/framework/context';

import { OrganizationAuthenticationGuard } from './OrganizationAuthenticationGuard';
import { OrganizationsSidebar } from './organizations-sidebar/OrganizationsSidebar';

interface DashboardProps {
  children: React.ReactNode;
}

export function DashboardLayout({ children }: DashboardProps) {
  const { classes, cx } = useStyles();
  const { extraLayout = {} } = useAppConfig();
  const hasSupportSeat = useHasSupportSeat();
  const { isMissingAllPermissions, isSuperAdmin } = usePermissionAccess();

  const {
    dashboardDataLayout: DataLayout,
    globalLayout,
    options = {},
  } = extraLayout;

  const hasNoPermissions =
    isMissingAllPermissions && !isSuperAdmin && !hasSupportSeat;

  const content = useMemo(
    () => (
      <>
        {options.isMobile ? null : <OrganizationsSidebar />}

        <motion.div layout className={cx(classes.main, 'page-main')}>
          <Navbar />

          <div
            id="page-content"
            className={cx(classes.content, 'page-content')}
          >
            {hasNoPermissions ? (
              <Page404
                hideActionButton
                hideImage
                title="Missing permissions"
                description="We're sorry, it looks like you don't have the necessary permissions to access this page. Please reach out to your administrator for assistance."
              />
            ) : (
              children
            )}
          </div>

          {globalLayout ? globalLayout() : null}

          <OrganizationGlobalSubscription />
        </motion.div>
      </>
    ),
    [
      children,
      classes.content,
      classes.main,
      cx,
      globalLayout,
      hasNoPermissions,
      options.isMobile,
    ]
  );

  return (
    <OrganizationAuthenticationGuard>
      <QuickNavProvider>
        <Box className={cx(classes.wrapper, 'page-wrapper')}>
          {DataLayout ? <DataLayout>{content}</DataLayout> : <>{content}</>}
        </Box>
      </QuickNavProvider>
    </OrganizationAuthenticationGuard>
  );
}

const useStyles = createStyles((theme) => ({
  main: {
    position: 'relative',
    height: '100%',
    overflow: 'hidden',
    backgroundColor: theme.white,
    letterSpacing: '0.02em',

    display: 'grid',
    gridTemplateRows: `${theme.other.navbarHeight}px 1fr`,
  },
  wrapper: {
    height: '100vh',
    overflow: 'hidden',
    display: 'grid',
    gridTemplateColumns: `100px 1fr`,
  },
  content: {
    flex: 1,
    overflow: 'auto',
    position: 'relative',
  },
}));
