import { LoadingOverlay } from '@mantine/core';
import React, { useCallback } from 'react';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { useEffectOnce } from 'react-use';

import {
  GettingStartedToursNamesEnum,
  useClaimDeviceByQrCode,
  useCompleteGettingStartedTour,
  useIsCecEnabled,
  useCheckQrCodeAccess,
  useIsFirstClaimedDevice,
  useOrganizationConfig,
  usePortalCapabilities,
} from '@portals/api/organizations';
import {
  isCustomerFeatureAll,
  isCustomerFeatureLabOnly,
} from '@portals/framework';
import { useOpenRouteModal } from '@portals/framework/route-modals';
import { ReactComponent as TrendUp } from '@portals/icons/bulk/trend-up.svg';
import { useOpenModal } from '@portals/redux';

import { QR_SEARCH_PARAM } from '../../constants/global-search-params.constants';
import { TourCompletedModalProps } from '../modals';

export function ClaimDeviceByQrCode() {
  const isFirstClaimedDevice = useIsFirstClaimedDevice();
  const openModal = useOpenModal();
  const openRouteModal = useOpenRouteModal();
  const completeGettingStartedTour = useCompleteGettingStartedTour();
  const portalCapabilities = usePortalCapabilities();
  const navigate = useNavigate();
  const claimDeviceByQrCode = useClaimDeviceByQrCode();
  const organizationConfig = useOrganizationConfig();

  const isCecEnabled = useIsCecEnabled();
  const checkQrCodeAccess = useCheckQrCodeAccess();

  const params = useParams<{ cloudId: string }>();
  const [searchParams] = useSearchParams();

  const showClaimDeviceTourCompletedModal = useCallback(() => {
    if (!portalCapabilities || !organizationConfig) {
      return false;
    }

    return (
      isCustomerFeatureAll(portalCapabilities.onboarding_guides) ||
      isCustomerFeatureLabOnly(
        portalCapabilities.onboarding_guides,
        organizationConfig.lab
      )
    );
  }, [portalCapabilities, organizationConfig]);

  const onClaimDevice = (qrCode: string) => {
    if (isCecEnabled) {
      checkQrCodeAccess.mutate(
        { qr: qrCode },
        {
          onSuccess: (response) => {
            if (response.claim_allowed) {
              onClaimAllowedDevice(qrCode);
            } else {
              openRouteModal({
                modalId: 'connect',
                pathParams: [response.device_partner_name, qrCode],
                backgroundPath: '/',
                replace: true,
              });
            }
          },
          onError: () => {
            navigate('/', { replace: true });
          },
        }
      );
    } else {
      onClaimAllowedDevice(qrCode);
    }
  };

  const onClaimAllowedDevice = (qrCode: string) => {
    claimDeviceByQrCode.mutate(
      { qr: qrCode },
      {
        onSuccess: async () => {
          const isFirstClaimed = await isFirstClaimedDevice.refetch();

          if (isFirstClaimed.data) {
            completeGettingStartedTour.mutate('claim_device_completed');

            if (showClaimDeviceTourCompletedModal()) {
              openModal<TourCompletedModalProps['data']>('TourCompletedModal', {
                title: 'You’ve just claimed your first device',
                nextTourTitle: 'Monitor and manage devices',
                nextTourDescription:
                  'Learn how to effectively monitor, manage, and configure your devices, empowering you to keep track of their performance and make necessary adjustments as needed.',
                nextTourIcon: <TrendUp />,
                onNextTourClick: () => {
                  navigate(
                    `/overview/?active_tour=${GettingStartedToursNamesEnum.Monitoring}`
                  );
                },
              });
            }
          }
        },
        onSettled: () => navigate('/'),
      }
    );
  };

  useEffectOnce(function claimDeviceOnQrParam() {
    const qrCode = searchParams.get(QR_SEARCH_PARAM) || params.cloudId;

    if (qrCode) {
      onClaimDevice(qrCode);
    } else {
      navigate('/');
    }
  });

  return <LoadingOverlay visible />;
}
