import {
  Modal,
  Stack,
  Text,
  ModalProps as MantineModalProps,
  createStyles,
  Card,
} from '@mantine/core';
import React, { useMemo, useState } from 'react';

import { SpaceType, useDeviceModels } from '@portals/api/organizations';
import { SearchInput } from '@portals/core';
import { DeviceAvatar, ModalProps } from '@portals/framework';
import { useOpenModal } from '@portals/redux';

import { AddDeviceModalProps } from './AddDeviceModal';

export interface SelectModelModalProps
  extends ModalProps<{ spaceId?: SpaceType['id'] }> {}

export function SelectModelModal({
  closeMe,
  data: { spaceId },
}: SelectModelModalProps) {
  const { classes } = useStyles();

  const [searchTerm, setSearchTerm] = useState('');

  const deviceModels = useDeviceModels();
  const openModal = useOpenModal();

  const lowerCaseSearchTerm = searchTerm.toLowerCase();

  const models = useMemo(
    () =>
      deviceModels.data?.filter((deviceModel) =>
        deviceModel.model.toLowerCase().includes(lowerCaseSearchTerm)
      ),
    [deviceModels.data, lowerCaseSearchTerm]
  );

  return (
    <Modal opened onClose={closeMe} styles={modalStyles} title="Add new device">
      <Stack spacing="xl" align="center" mt="xxl">
        <Text size={24} data-testid="choose-device-model-title">
          Choose your device model{' '}
        </Text>

        <SearchInput
          radius="md"
          size="md"
          mx="auto"
          w={430}
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          onClear={() => setSearchTerm('')}
          data-testid="search-input"
        />

        {models && models?.length > 0 ? (
          <div className={classes.grid}>
            {models.map((model) => (
              <Card
                key={model.id}
                radius="lg"
                p={0}
                className={classes.modelContainer}
                onClick={() => {
                  closeMe();
                  openModal<AddDeviceModalProps['data']>('AddDeviceModal', {
                    spaceId,
                    selectedModel: model,
                  });
                }}
              >
                <Card.Section m={0}>
                  <DeviceAvatar
                    img={model.user_settings?.media?.img}
                    icon={model.user_settings?.icon}
                    size={280}
                    imageProps={{ classNme: classes.modelImage }}
                  />
                </Card.Section>

                <Text
                  size="lg"
                  color="blue_gray.9"
                  className={classes.modelName}
                  align="center"
                  data-testid={`select-device-model-name-${model.model}`}
                >
                  {model.model}
                </Text>
              </Card>
            ))}
          </div>
        ) : null}
      </Stack>
    </Modal>
  );
}

const modalStyles: MantineModalProps['styles'] = (theme) => ({
  content: {
    minWidth: '100%',
    minHeight: '100%',
    display: 'grid',
    gridTemplateRows: 'min-content 1fr',
  },

  body: {
    backgroundColor: theme.colors.gray[0],
  },

  header: {
    padding: theme.spacing.xl,
  },
});

const useStyles = createStyles((theme) => ({
  modelContainer: {
    border: `1px solid ${theme.colors.gray[2]}`,
    cursor: 'pointer',

    '&:hover': {
      boxShadow: '0px 5px 12px 0px rgba(38, 50, 56, 0.13)',
    },
  },

  modelName: {
    padding: theme.spacing.xl,
    borderTop: `1px solid ${theme.colors.gray[2]}`,
  },

  modelImage: {
    objectFit: 'contain',
  },

  grid: {
    display: 'grid',
    gridTemplateColumns: 'repeat(auto-fill, 280px)',
    gap: theme.spacing.xl,
    maxWidth: '90%',
  },
}));
