import { Box, createStyles, Group, Stack, Text } from '@mantine/core';
import React, { forwardRef } from 'react';

import { DeviceModelSearchResultType } from '@portals/api/organizations';
import { DeviceAvatar } from '@portals/framework';

interface DeviceModelSearchResultProps {
  deviceModel: DeviceModelSearchResultType;
  isHovered: boolean;
  handleSelectedDeviceModel: () => void;
}

export const DeviceModelSearchResult = forwardRef<
  HTMLDivElement,
  DeviceModelSearchResultProps
>(({ deviceModel, isHovered, handleSelectedDeviceModel }, ref) => {
  const { classes, cx } = useStyles();

  return (
    <Box
      ref={ref}
      className={cx({
        [classes.hoveredItem]: isHovered,
      })}
      onClick={handleSelectedDeviceModel}
    >
      <Stack spacing="xs" className={classes.container}>
        <Group p="xs" sx={{ cursor: 'pointer' }}>
          <DeviceAvatar
            img={deviceModel.user_settings?.media?.img}
            icon={deviceModel.user_settings?.icon}
            w={46}
            h={46}
            radius="md"
          />

          <div>
            <Text weight={500}>{deviceModel.name}</Text>
            <Text weight={500} color="gray.5" size="xs">
              By {deviceModel.partner_display_name}
            </Text>
          </div>
        </Group>
      </Stack>
    </Box>
  );
});
const useStyles = createStyles((theme) => ({
  container: {
    borderRadius: theme.radius.md,

    '&:hover': {
      backgroundColor: theme.colors.gray[1],
    },
  },
  hoveredItem: {
    borderColor: theme.colors.gray[5],
    backgroundColor: theme.colors.gray[2],
  },
}));
