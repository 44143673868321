import {
  Badge,
  BadgeProps,
  Button,
  Center,
  createStyles,
  LoadingOverlay,
  Stack,
  Text,
} from '@mantine/core';
import React, { useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import {
  useOrganizationConfig,
  useSendSupportEmail,
} from '@portals/api/organizations';
import { useCurrentUser } from '@portals/api/ui';
import { ModalCenteredMediaLayout } from '@portals/core';
import { useIntegrations } from '@portals/redux';

import { CecConnectionCard } from './CecConnectionCard';
import {
  OrgC2CIntegrationItem,
  useIntegrationsList,
} from '../../../hooks/c2c-integrations';

interface C2CIntegrationsProps {
  searchTerm: string;
}

export function C2CIntegrations({ searchTerm }: C2CIntegrationsProps) {
  const organizationConfig = useOrganizationConfig();
  const currentUser = useCurrentUser();

  const { classes } = useStyles();

  const navigate = useNavigate();

  const integrations = useIntegrations();

  const sendSupportEmail = useSendSupportEmail();

  const [selectedComingSoonIntegration, setSelectedComingSoonIntegration] =
    useState<OrgC2CIntegrationItem | null>(null);

  const onConnectClick = (integration: OrgC2CIntegrationItem) => {
    if (integration.isComingSoon) {
      sendSupportEmail.mutate(
        {
          title: `${organizationConfig?.name} is asking to integrate with ${integration.name}`,
          message: `Dear Support team member,

${organizationConfig?.name} has asked to integrate with ${integration.name}.
Please get back to the requesting user: ${currentUser.data?.name} at ${currentUser.data?.email} to understand their need.

The Xyte team`,
        },
        {
          onSuccess: () => {
            setSelectedComingSoonIntegration(integration);
          },
        }
      );
    } else if (integration.href) {
      navigate(integration.href);
    }
  };

  const integrationsList = useIntegrationsList();

  const filteredIntegrations = useMemo(() => {
    if (searchTerm === '') return integrationsList;

    const lowerSearchTerm = searchTerm.toLowerCase();

    return integrationsList.filter((integration) => {
      const searchableContent =
        `${integration.name}__@@__${integration.description}`.toLowerCase();

      return searchableContent.includes(lowerSearchTerm);
    });
  }, [integrationsList, searchTerm]);

  if (filteredIntegrations.length === 0) {
    return null;
  }

  return (
    <>
      <LoadingOverlay visible={sendSupportEmail.isLoading} />

      {filteredIntegrations.map((integration) => (
        <CecConnectionCard
          key={integration.id}
          id={integration.id}
          logo={integration.logo}
          title={integration.name}
          description={integration.description}
          isComingSoon={integration.isComingSoon}
          isConnected={integrations?.[integration.id]?.state === 'active'}
          searchTerm={searchTerm}
          action={
            <Button
              onClick={() => onConnectClick(integration)}
              data-testid={
                integration.isComingSoon
                  ? 'c2c-card-ask-to-connect-btn'
                  : 'c2c-card-connect-btn'
              }
            >
              {integration.isComingSoon ? 'Ask to connect' : 'Connect'}
            </Button>
          }
        />
      ))}

      {selectedComingSoonIntegration && (
        <ModalCenteredMediaLayout
          opened
          onClose={() => setSelectedComingSoonIntegration(null)}
          title={
            <Stack align="center">
              <Badge size="lg" styles={badgeStyles}>
                Coming soon
              </Badge>
              <Text>{selectedComingSoonIntegration.name} is coming soon</Text>
            </Stack>
          }
          classNames={{
            header: classes.header,
            headerContent: classes.headerContent,
          }}
          mediaSize={80}
          media={
            <img
              src={selectedComingSoonIntegration.logo}
              alt={selectedComingSoonIntegration.name}
            />
          }
          footer={
            <Center>
              <Button
                size="md"
                w={160}
                onClick={() => setSelectedComingSoonIntegration(null)}
              >
                Got it
              </Button>
            </Center>
          }
        >
          <Text ta="center">
            Our Customer Support team will inform you once this integration is
            available. Stay tuned!
          </Text>
        </ModalCenteredMediaLayout>
      )}
    </>
  );
}

const useStyles = createStyles((theme) => ({
  header: {
    paddingBottom: theme.spacing.md,
  },
  headerContent: {
    gap: theme.spacing.xs,
  },
}));

const badgeStyles: BadgeProps['styles'] = (theme) => ({
  root: {
    textTransform: 'none',
    backgroundColor: theme.fn.rgba(theme.colors.indigo[0], 0.6),
  },
  inner: {
    fontSize: theme.fontSizes.xs,
    fontWeight: 400,
    color: theme.colors.gray[9],
  },
});
