import {
  Box,
  Button,
  createStyles,
  Group,
  InputBase,
  LoadingOverlay,
  Modal,
  Stack,
  Text,
} from '@mantine/core';
import React, { useState } from 'react';
import { IMaskInput } from 'react-imask';

import {
  useCheckRedeemableCode,
  useDevice,
  useRedeemCode,
} from '@portals/api/organizations';
import { DeviceAvatar, ModalProps } from '@portals/framework';
import { ReactComponent as ArrowDown } from '@portals/icons/linear/arrow-down-1.svg';
import { LicenseType } from '@portals/types';

import { RedeemableLicense } from './RedeemableLicense';

export interface RedeemLicenseCodeModalProps
  extends ModalProps<{
    deviceId: string;
    onSuccess?: () => void;
  }> {}

function isCodeFullyEntered(input: string): boolean {
  // Check if the input matches the expected pattern
  const pattern = /^[A-Z0-9]{4}-[A-Z0-9]{4}-[A-Z0-9]{4}$/;

  return pattern.test(input);
}

export function RedeemLicenseCodeModal({
  data,
  closeMe,
}: RedeemLicenseCodeModalProps) {
  const { classes } = useStyles();
  const device = useDevice(data.deviceId);

  const [error, setError] = useState<string | null>(null);
  const [licenseToRedeem, setLicenseToRedeem] = useState<LicenseType | null>(
    null
  );
  const [codeToRedeem, setCodeToRedeem] = useState<string | null>(null);

  const checkRedeemableCode = useCheckRedeemableCode();
  const redeemCode = useRedeemCode();

  const onInputRef = (inputRef: HTMLInputElement) => {
    // Focus the input after a short delay, otherwise doesn't work
    setTimeout(() => {
      inputRef?.focus?.();
    }, 100);
  };

  const onAccept = (value: string) => {
    if (error) {
      setError(null);
    }

    if (isCodeFullyEntered(value)) {
      checkRedeemableCode.mutate(
        {
          shareableToken: value,
          deviceId: data.deviceId,
        },
        {
          onError: (error) => {
            setError(error?.error || 'An error occurred');
          },
          onSuccess: (license) => {
            setLicenseToRedeem(license);
            setCodeToRedeem(value);
          },
        }
      );
    } else {
      setCodeToRedeem(null);
    }
  };

  const onRedeemCode = () => {
    redeemCode.mutate(
      {
        shareableToken: codeToRedeem,
        deviceId: data.deviceId,
      },
      {
        onSuccess: () => {
          closeMe();
          data?.onSuccess?.();
        },
      }
    );
  };

  return (
    <Modal
      opened={true}
      onClose={closeMe}
      withCloseButton
      padding={0}
      styles={{
        content: {
          maxWidth: '468px !important',
          padding: 32,
        },
      }}
    >
      <Stack spacing="xl">
        <LoadingOverlay visible={redeemCode.isLoading} />

        <Text size="xl" align="center" color="gray.9">
          Assign license with a code
        </Text>

        <Stack align="center" spacing="md">
          <Text size="sm" color="gray.9">
            Enter your license code
          </Text>

          <InputBase
            // @ts-ignore - its TS setup is wrong, doesn't recognize existing prop
            lazy={false}
            error={error}
            errorProps={{ ta: 'center' }}
            component={IMaskInput}
            mask="****-****-****"
            prepareChar={(str) => str.toUpperCase()}
            overwrite
            inputRef={onInputRef}
            classNames={{
              input: classes.input,
            }}
            onAccept={onAccept}
            data-testid="license-code-input"
          />
        </Stack>

        <Stack align="center" justify="center" spacing="xl">
          <RedeemableLicense
            license={licenseToRedeem}
            isLoading={checkRedeemableCode.isLoading}
          />

          <ArrowDown />
        </Stack>

        <Box className={classes.deviceContainer}>
          <DeviceAvatar
            img={device.data?.model_settings?.media?.img}
            icon={device.data?.model_settings?.icon}
            size={48}
          />

          <Stack justify="space-between" spacing={4}>
            <Text size="lg" color="gray.9" weight={500}>
              {device.data.name}
            </Text>

            <Text size="sm" color="gray.7">
              {device.data.space_tree_path_name.replace('/', ' / ')}
            </Text>
          </Stack>
        </Box>

        <Group position="right">
          <Button variant="default" onClick={closeMe}>
            Cancel
          </Button>

          <Button
            disabled={!!error || !licenseToRedeem}
            onClick={onRedeemCode}
            loading={redeemCode.isLoading}
            data-testid="assign-button"
          >
            Assign
          </Button>
        </Group>
      </Stack>
    </Modal>
  );
}

const useStyles = createStyles((theme) => ({
  deviceContainer: {
    padding: theme.spacing.md,
    borderRadius: theme.radius.md,
    border: `1px solid ${theme.colors.gray[2]}`,
    display: 'grid',
    gridTemplateColumns: '48px 1fr',
    alignItems: 'center',
    gap: theme.spacing.sm,
  },
  input: {
    textAlign: 'center',
    letterSpacing: '0.1em',
  },
}));
