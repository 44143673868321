import {
  BackgroundImage,
  Button,
  createStyles,
  Group,
  Stack,
  Text,
} from '@mantine/core';
import React, {
  useCallback,
  useDeferredValue,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useSearchParams } from 'react-router-dom';

import {
  useCecPartners,
  useClaimDeviceByQrCode,
} from '@portals/api/organizations';
import { SearchInput } from '@portals/core';
import { RouteModal, useRouteModals } from '@portals/framework/route-modals';
import { useOpenModal } from '@portals/redux';
import { CecPartnerType } from '@portals/types';

import { C2CIntegrations } from './C2CIntegrations';
import { CecConnectionCard } from './CecConnectionCard';
import headerBackgroundImageSrc from './header-bg-image.svg';
import findPartnerBackground from '../../../assets/img/find-partner-background.svg';
import {
  CLOUD_ID_SEARCH_PARAM,
  PARTNER_SEARCH_PARAM,
} from '../../../constants/global-search-params.constants';
import { useOpenClaimDeviceModal } from '../../components/ClaimDeviceButton';
import {
  ConfirmCecConnectionModalProps,
  ConnectionSuccessModalProps,
} from '../../modals';

export function ConnectRouteModal() {
  return (
    <RouteModal modalId="connect">
      <ConnectRouteModalContent />
    </RouteModal>
  );
}

function ConnectRouteModalContent() {
  const { classes } = useStyles();

  const cecPartners = useCecPartners();
  const claimDeviceByQrCode = useClaimDeviceByQrCode();

  const routeModals = useRouteModals();
  const openModal = useOpenModal();
  const openClaimDeviceModal = useOpenClaimDeviceModal();

  const [searchParams, setSearchParams] = useSearchParams();

  const onConnectionSuccess = useCallback(
    (cecPartner: CecPartnerType) => {
      const cloudIdSearchParam = searchParams.get(CLOUD_ID_SEARCH_PARAM);

      if (cloudIdSearchParam) {
        claimDeviceByQrCode.mutate(
          { qr: cloudIdSearchParam },
          {
            onSuccess: () => {
              setSearchParams(
                (prev) => {
                  prev.delete(CLOUD_ID_SEARCH_PARAM);
                  return prev;
                },
                { replace: true }
              );
              routeModals.onClose();
            },
          }
        );
      } else {
        openModal<ConnectionSuccessModalProps['data']>(
          'ConnectionSuccessModal',
          {
            cecPartner,
            onOpenClaimDevice: () => {
              routeModals.onClose();
              openClaimDeviceModal({ partnerId: cecPartner.id });
            },
          }
        );
      }
    },
    [
      claimDeviceByQrCode,
      openClaimDeviceModal,
      openModal,
      routeModals,
      searchParams,
      setSearchParams,
    ]
  );

  const openConfirmCecConnectionModal = useCallback(
    (cecPartner: CecPartnerType) => {
      openModal<ConfirmCecConnectionModalProps['data']>(
        'ConfirmCecConnectionModal',
        {
          cecPartner,
          onConnectionSuccess: () => {
            onConnectionSuccess(cecPartner);
          },
        }
      );
    },
    [onConnectionSuccess, openModal]
  );

  useEffect(
    function initSelectedPartnerFromSearchParams() {
      if (!cecPartners.data) return;

      const partnerSearchParam = searchParams.get(PARTNER_SEARCH_PARAM);

      if (!partnerSearchParam) return;

      const partner = cecPartners.data.find(
        (cecPartner) => cecPartner.name === partnerSearchParam
      );

      // If there's a partner query param, and it's not connected, open the `ConfirmCecConnectionModal`
      if (partner && !partner.connected) {
        openConfirmCecConnectionModal(partner);
      }

      // Clear the partner query param
      setSearchParams(
        (prevParams) => {
          prevParams.delete(PARTNER_SEARCH_PARAM);

          return prevParams;
        },
        { replace: true }
      );
    },
    [
      cecPartners.data,
      openConfirmCecConnectionModal,
      searchParams,
      setSearchParams,
    ]
  );

  const [searchTerm, setSearchTerm] = useState('');
  const deferredSearchTerm = useDeferredValue(searchTerm);

  const isStale = searchTerm !== deferredSearchTerm;

  const filteredPartners = useMemo(() => {
    // List only partners that have `self_signup` enabled
    const partnersList = (cecPartners.data || []).filter(
      (partner) => partner.organization_signup_enabled || partner.connected
    );

    if (!deferredSearchTerm) return partnersList;

    const lowerSearchTerm = deferredSearchTerm.toLowerCase();

    return partnersList.filter((partner) => {
      const searchableContent =
        `${partner.display_name}__@@__${partner.about}`.toLowerCase();

      return searchableContent.includes(lowerSearchTerm);
    });
  }, [cecPartners.data, deferredSearchTerm]);

  return (
    <div className={classes.contentWrapper}>
      <BackgroundImage
        src={headerBackgroundImageSrc}
        className={classes.header}
      >
        <Stack spacing="xl" pt={40}>
          <Text className={classes.title} data-testid="connect-modal-title">
            Unlock Endless Possibilities
          </Text>

          <Text size="md" weight={500} color="gray.9">
            Discover a world of products and services from leading brands,
            personalized just for you.
          </Text>
        </Stack>

        <SearchInput
          radius="md"
          size="md"
          mt="xl"
          mx="auto"
          maw={440}
          w="100%"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          onClear={() => setSearchTerm('')}
          loading={isStale}
        />
      </BackgroundImage>

      <div className={classes.bodyContent}>
        <Stack spacing="xxl" w="100%">
          {filteredPartners.length > 0 && (
            <div className={classes.grid}>
              {filteredPartners.map((partner) => (
                <CecConnectionCard
                  id={partner.id}
                  key={partner.id}
                  logo={partner.logo}
                  searchTerm={deferredSearchTerm}
                  isConnected={partner.connected}
                  title={partner.display_name}
                  description={partner.about}
                  action={
                    <Button
                      onClick={() => openConfirmCecConnectionModal(partner)}
                      data-testid="connect-brand-button"
                    >
                      Connect
                    </Button>
                  }
                />
              ))}

              <C2CIntegrations searchTerm={deferredSearchTerm} />
            </div>
          )}
        </Stack>

        <BackgroundImage p="xxl" radius="lg" src={findPartnerBackground}>
          <Group position="apart">
            <Stack spacing="xs" c="gray.9">
              <Text size="lg" weight={500}>
                Can't find your brand?
              </Text>
              <Text>
                Let us know which brand you'd like to see on our platform.
              </Text>
            </Stack>

            <Button
              variant="default"
              onClick={() => openModal('SuggestBrandModal')}
              data-testid="suggest-a-brand-button"
            >
              Suggest a brand
            </Button>
          </Group>
        </BackgroundImage>
      </div>
    </div>
  );
}

const useStyles = createStyles((theme) => ({
  contentWrapper: {
    overflow: 'auto',
    height: '100%',
    display: 'grid',
    gridTemplateRows: 'auto 1fr',
    backgroundColor: theme.colors.gray[0],
  },
  header: {
    zIndex: 1,
    position: 'sticky',
    top: -132,
    textAlign: 'center',
    paddingBottom: 40,
    paddingInline: theme.spacing.md,

    [theme.fn.smallerThan('md')]: {
      position: 'static',
    },
  },
  title: {
    fontSize: 36,
    fontWeight: 600,
    color: theme.colors.gray[9],
  },
  grid: {
    display: 'grid',
    gridTemplateColumns: 'repeat(auto-fill, minmax(290px, 1fr))',
    gridAutoRows: 'minmax(290px, auto)',
    gap: theme.spacing.xl,
    placeContent: 'start',
  },
  bodyContent: {
    display: 'grid',
    gridTemplateRows: '1fr auto',
    gap: theme.spacing.xxl,
    height: '100%',
    maxWidth: 1280,
    width: '100%',
    marginInline: 'auto',
    paddingBlock: theme.spacing.xxl,
    paddingInline: theme.spacing.lg,
  },
}));
